import axios from 'axios';
import { StateCreator, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import * as Sentry from '@sentry/react';

import { Note } from 'fixzy-dialler-common/src/components';

import config from 'fixzy-dialler-common/src/config';

//auth list slices
interface AuthSlice {
  appType: string;
  setAppType: (appType: string) => void;
  accessToken: string | null;
  isLoggedIn: boolean;
  userName: string;
  setAccessTokenStore: (value: string | null, userName: string) => void;
}

const createAuthSlice: StateCreator<State, [['zustand/devtools', never]], [], AuthSlice> = (
  set,
) => ({
  appType: '',
  setAppType: (appType: string) => set(() => ({ appType: appType })),
  accessToken: '',
  userName: '',
  isLoggedIn: false,
  setAccessTokenStore: (value: string | null, userName: string) =>
    set(() => ({ accessToken: value, isLoggedIn: true, userName: userName })),
});

//permission list slices
interface PermissionsSlice {
  userEnabled: boolean;
  setUserEnabled: () => void;
  audioState: boolean;
  videoState: boolean;
  screenShareState: boolean;
  setScreenShareState: (screenShare: boolean) => void;
  streamSentState: boolean;
  setStreamSentState: (streamSent: boolean) => void;
  consumerScreenShareState: boolean;
  setConsumerScreenShareState: (isSet: boolean) => void;
  setTrackState: (audio: boolean, video: boolean) => void;
  recordState?: string;
  uploadState: boolean;
  setUploadState: (uploadState: boolean) => void;
  endCallState: boolean;
  setRecordState: (recordState: boolean) => void;
  setEndCallState: (endCallState: boolean) => void;
  openingMeasureApp: boolean;
  setOpeningMeasureApp: (b: boolean) => void;
  continueRecordingInMeasureApp: boolean;
  setContinueRecordingInMesaureApp: (b: boolean) => void;
}

const createPermissionsSlice: StateCreator<
  State,
  [['zustand/devtools', never]],
  [],
  PermissionsSlice
> = (set) => ({
  userEnabled: false,
  setUserEnabled: () => set(() => ({ userEnabled: true })),
  audioState: true,
  videoState: true,
  screenShareState: false,
  setScreenShareState: async (screenShareState: boolean) =>
    set(() => ({ screenShareState: screenShareState })),
  streamSentState: true,
  setStreamSentState: async (streamSentState: boolean) =>
    set(() => ({ streamSentState: streamSentState })),
  consumerScreenShareState: false,
  setConsumerScreenShareState: (consumerScreenShareState: boolean) =>
    set(() => ({ consumerScreenShareState })),
  setTrackState: (audioState: boolean, videoState: boolean) =>
    set(() => ({ audioState, videoState })),
  recordState: undefined,
  uploadState: false,
  setUploadState: (uploadState: boolean) => set(() => ({ uploadState })),
  endCallState: false,
  setRecordState: (recordState: boolean) =>
    set(() =>
      recordState ? { recordState: new Date().toISOString() } : { recordState: undefined },
    ),
  setEndCallState: (endCallState: boolean) => set(() => ({ endCallState })),
  openingMeasureApp: false,
  setOpeningMeasureApp: (openingMeasureApp: boolean) => set(() => ({ openingMeasureApp })),
  continueRecordingInMeasureApp: false,
  setContinueRecordingInMesaureApp: (continueRecordingInMeasureApp: boolean) =>
    set(() => ({ continueRecordingInMeasureApp })),
});

//job list slices
interface JobsListSlice {
  jobsList: string;
  setJobsList: () => void;
}

const createJobsListSlice: StateCreator<State, [['zustand/devtools', never]], [], JobsListSlice> = (
  set,
) => ({
  jobsList: '',
  setJobsList: () => set(() => ({ jobsList: 'hello' })),
});

// Form Fields slices

interface FormFieldsSlice {
  attendanceId: string;
  setAttendanceId: (attendanceId: string) => void;
}

const createFormFieldsSlice: StateCreator<
  State,
  [['zustand/devtools', never]],
  [],
  FormFieldsSlice
> = (set) => ({
  attendanceId: '',
  setAttendanceId: async (attendanceId: string) => set(() => ({ attendanceId: attendanceId })),
});

// Form Note slices

interface NoteSlice {
  notes: Note[];
  addNote: (note: Note) => Promise<void>;
  notesIsOpen: boolean;
  setNotesIsOpen: (notesIsOpen: boolean) => void;
}

const createNoteSlice: StateCreator<State, [['zustand/devtools', never]], [], NoteSlice> = (
  set,
) => ({
  notes: [],
  addNote: async (note: Note) => {
    try {
      await axios.post(`${config.API_URL}/Attendance/Note`, note);
      set((state) => ({ notes: [...state.notes, note] }));
    } catch (error) {
      Sentry.captureException(error);
      return Promise.reject();
    }
  },
  notesIsOpen: false,
  setNotesIsOpen: (notesIsOpen) => set(() => ({ notesIsOpen: notesIsOpen })),
});

// Call slices

interface CallSlice {
  showOpenAppButton: boolean;
  setShowOpenAppButton: (shouldShow: boolean) => void;
}

const createCallSlice: StateCreator<State, [['zustand/devtools', never]], [], CallSlice> = (
  set,
) => ({
  showOpenAppButton: false,
  setShowOpenAppButton: (shouldShow: boolean) => set({ showOpenAppButton: shouldShow }),
});

interface MediaSlice {
  startTime: string;
  setStartTime: (startTime: string) => void;
  endTime: string;
  setEndTime: (endTime: string) => void;
  takenImages: {
    uploading?: boolean;
    src: string;
    timeStamp: string;
  }[];
  takenVideos: {
    uploading?: boolean;
    filePath: string;
    timeStamp: string;
    thumbnailPath: string;
  }[];
}

const createMediaSlice: StateCreator<State, [['zustand/devtools', never]], [], MediaSlice> = (
  set,
) => ({
  startTime: '',
  setStartTime: (startTime: string) => set({ startTime }),
  endTime: '',
  setEndTime: (endTime: string) => set({ endTime }),
  takenImages: [],
  takenVideos: [],
});

export type GalleryTypes = 'photos' | 'videos' | 'none';

interface GallerySlice {
  openGallery: GalleryTypes;
  setOpenGallery: (gallery: GalleryTypes) => void;
}

const createGallerySlice: StateCreator<State, [['zustand/devtools', never]], [], GallerySlice> = (
  set,
) => ({
  openGallery: 'none',
  setOpenGallery: (gallery: GalleryTypes) => set({ openGallery: gallery }),
});

type State = AuthSlice &
  PermissionsSlice &
  JobsListSlice &
  FormFieldsSlice &
  NoteSlice &
  CallSlice &
  MediaSlice &
  GallerySlice;

export const useBoundStore = create<State>()(
  devtools((...a) => ({
    ...createAuthSlice(...a),
    ...createPermissionsSlice(...a),
    ...createJobsListSlice(...a),
    ...createFormFieldsSlice(...a),
    ...createNoteSlice(...a),
    ...createCallSlice(...a),
    ...createMediaSlice(...a),
    ...createGallerySlice(...a),
  })),
);
