import { useBoundStore } from '@fixzy/agent-app/src/store';
import Axios from 'axios';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ChannelMessage } from '../../enums';

import configJson from '../config';

dayjs.extend(utc); // utc formatted dates

// Helper function: generate a file from base64 String
const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');

  if (arr && arr[0]) {
    const mimeSplit = (arr[0] as string).match(/:(.*?);/);
    const mime = mimeSplit ? mimeSplit[1] : undefined;

    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1;
    }

    return new File([u8arr], filename, { type: mime });
  }

  return null;
};

const errorLog = (error: any) => {
  if (error.response) {
    // Request made and server responded
    console.log(error.response);
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }

  return error.response ? error.response : null;
};

export const uploadBase64Photo = async (
  base64Image: string,
  attendanceId: string,
  sendMessage: (message: string) => void,
) => {
  const timestamp = dayjs().utc().format();
  // The file name will be redefined by the server
  const file = dataURLtoFile(base64Image, `snapshot_${timestamp}.jpg`);

  if (file) return await uploadMedia(file, attendanceId, sendMessage, base64Image);
  // Error
  return null;
};

export const uploadVideo = async (
  video: string,
  attendanceId: string,
  sendMessage: (message: string) => void,
) => {
  const mediaBlob = await fetch(video).then((response) => response.blob());

  const timestamp = dayjs().utc().format();

  const myFile = new File([mediaBlob], `recording_${timestamp}.mp4`, { type: 'video/mp4' });
  // The file name will be redefined by the server
  if (video) return await uploadMedia(myFile, attendanceId, sendMessage);

  // Error
  return null;
};

export const uploadMedia = async (
  file: File,
  attendance: string,
  sendMessage: (message: string) => void,
  base64Image?: string,
) => {
  sendMessage(ChannelMessage.isUploading);
  const url = configJson.API_URL + '/Attendance/Media';

  useBoundStore.setState({
    uploadState: true,
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const endTime = useBoundStore.getState().endTime;
  const startTime = useBoundStore.getState().startTime;

  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('attendanceId', attendance);
  if (file.type === 'video/mp4') {
    formData.append('endTime', endTime);
    formData.append('startTime', startTime);

    const takenVideos = useBoundStore.getState().takenVideos;
    useBoundStore.setState({
      takenVideos: [
        {
          uploading: true,
          filePath: '',
          timeStamp: '',
          thumbnailPath: '',
        },
        ...takenVideos,
      ],
    });
  } else {
    const takenImages = useBoundStore.getState().takenImages;
    useBoundStore.setState({
      takenImages: [
        {
          uploading: true,
          src: '',
          timeStamp: '',
        },
        ...takenImages,
      ],
    });
  }

  try {
    const response = await Axios.post(url, formData, config);

    const takenImages = useBoundStore.getState().takenImages;
    const takenVideos = useBoundStore.getState().takenVideos;

    if (response.data.fileType === 'Image' && base64Image) {
      // remove an instane of uploading from the array
      const index = takenImages.findIndex((i) => i.uploading);
      if (index !== -1) {
        takenImages.splice(index, 1);
      }

      const isUploadingAnother = !![...takenImages, ...takenVideos].find((i) => i.uploading);
      if (!isUploadingAnother) {
        useBoundStore.setState({
          uploadState: false,
        });
        sendMessage(ChannelMessage.isNotUploading);
      }

      useBoundStore.setState({
        takenImages: [
          { src: base64Image, uploading: false, timeStamp: response.data.timeStamp },
          ...takenImages,
        ],
      });
    }
    if (response.data.fileType === 'Video') {
      // remove an instane of uploading from the array
      const index = takenVideos.findIndex((v) => v.uploading);
      if (index !== -1) {
        takenVideos.splice(index, 1);
      }

      const isUploadingAnother = !![...takenImages, ...takenVideos].find((v) => v.uploading);

      if (!isUploadingAnother) {
        useBoundStore.setState({
          uploadState: false,
        });
        sendMessage(ChannelMessage.isNotUploading);
      }

      useBoundStore.setState({
        takenVideos: [
          {
            uploading: false,
            filePath: response.data.sharedPath,
            timeStamp: response.data.timeStamp,
            thumbnailPath: response.data.thumbnailPath,
          },
          ...takenVideos,
        ],
      });
    }
  } catch (error: any) {
    errorLog(error);
    return error.response ? error.response : error;
  }
};
