import { useBoundStore } from '@fixzy/agent-app/src/store';
import { AppType, ChannelMessage } from '../../enums';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSignalR } from './SignalRContext';
import { beforeUnloadListener } from '../components/callStream/beforeUnloadListener';

export type UseCallMessageParams = {
  guid: string;
  leave: () => void;
  setAppInForeground: (inForeground: boolean) => void;
};

export const useCallMessages = ({ guid, leave, setAppInForeground }: UseCallMessageParams) => {
  const {
    connectionState,
    sendMessage,
    addCommandListener,
    addPayloadListener,
    joinChannel,
    removeCommandListener,
    removePayloadListener,
  } = useSignalR();

  const navigate = useNavigate();

  const appType = useBoundStore((state) => state.appType);
  const setConsumerScreenShareState = useBoundStore((state) => state.setConsumerScreenShareState);
  const setShowOpenAppButton = useBoundStore((state) => state.setShowOpenAppButton);
  const setRecordState = useBoundStore((state) => state.setRecordState);
  const setStreamSentState = useBoundStore((state) => state.setStreamSentState);
  const setOpeningMeasureApp = useBoundStore((state) => state.setOpeningMeasureApp);
  const setUploadState = useBoundStore((state) => state.setUploadState);
  const setStartTime = useBoundStore((state) => state.setStartTime);

  // Set up sending messages
  useEffect(() => {
    const sendVisibilityChanged = () => {
      if (document.visibilityState === 'hidden') {
        sendMessage(ChannelMessage.appBackgrounded);
      }
      if (document.visibilityState === 'visible') {
        sendMessage(ChannelMessage.appForegrounded);
      }
    };
    document.addEventListener('visibilitychange', sendVisibilityChanged);

    return () => document.removeEventListener('visibilitychange', sendVisibilityChanged);
  }, [sendMessage]);

  // Set up receiving messages
  useEffect(() => {
    const setup = async () => {
      const isConsumer = appType === AppType.consumer;

      if (connectionState === 'connected') {
        await joinChannel(guid as string);
      }

      if (connectionState === 'joined') {
        const payloadListeners: ReturnType<typeof addPayloadListener>[] = [];
        const commandListeners = [
          addCommandListener(ChannelMessage.callHasEnded, () => {
            leave();
            if (appType === AppType.agent) navigate('/endcall');
            else navigate(`/callrating/${guid}`);
          }),
        ];

        // Add consumer only listeners
        if (isConsumer) {
          commandListeners.concat([
            addCommandListener(ChannelMessage.isScreenShare, () =>
              setConsumerScreenShareState(true),
            ),
            addCommandListener(ChannelMessage.isNotScreenShare, () =>
              setConsumerScreenShareState(false),
            ),
            addCommandListener(ChannelMessage.openApp, () => setShowOpenAppButton(true)),
            addCommandListener(ChannelMessage.isStreamSent, () => setStreamSentState(true)),
            addCommandListener(ChannelMessage.isStreamNotSent, () => setStreamSentState(false)),
            addCommandListener(ChannelMessage.isNotRecording, () => setRecordState(false)),
            addCommandListener(ChannelMessage.isUploading, () => setUploadState(true)),
            addCommandListener(ChannelMessage.isNotUploading, () => setUploadState(false)),
            addCommandListener(ChannelMessage.appJoined, () => {
              leave();
              window.removeEventListener('beforeunload', beforeUnloadListener);
              window.close();
            }),
          ]);
          payloadListeners.push(
            addPayloadListener(ChannelMessage.isRecording, (_, payload) => {
              setRecordState(true);
              setStartTime(payload);
            }),
          );
        } else {
          // Add agent only listeners
          commandListeners.concat([
            addCommandListener(ChannelMessage.appBackgrounded, () => setAppInForeground(false)),
            addCommandListener(ChannelMessage.appForegrounded, () => {
              setAppInForeground(true);
            }),
            addCommandListener(ChannelMessage.appEndedCall, () => navigate('/endcall')),
            addCommandListener(ChannelMessage.openingMeasureApp, () => setOpeningMeasureApp(true)),
            addCommandListener(ChannelMessage.appJoined, () => {
              setOpeningMeasureApp(false);
              setAppInForeground(true);
            }),
          ]);
        }

        // cleanup listeners
        return () => {
          commandListeners.forEach((listener) => removeCommandListener(listener));
          payloadListeners.forEach((listener) => removePayloadListener(listener));
        };
      }
    };
    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionState]);
};
