import { useState, useRef, useEffect, ReactElement, useCallback, useMemo } from 'react';
import { useBoundStore } from '@fixzy/agent-app/src/store';
import { AppType } from 'fixzy-dialler-common/enums';
import StreamMixer, { getVideoTrack } from './streamMixer';
import { uploadVideo } from '../../api/helpers';
import { useShallow } from 'zustand/react/shallow';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';

import './callTools.scss';

import { ChannelMessage } from '../../../enums';
import { Size } from './drawing';
import Timer from './timer';
import { useCallMessages, useSignalR } from '../../hooks';
import { CallOverlayActions } from '../callOverlayActions/callOverlayActions';
import { BackgroundScreen } from '../backgroundScreen/backgroundScreen';
import { useParams } from 'react-router-dom';
import { ScreenShareScreen } from '../screenShareScreen/screenShareScreen';
import { ITrack } from 'agora-rtc-react';
import { fetchFile } from '@ffmpeg/util';
import { getFFmpeg } from './getFFmpeg';
import useRecordingTimer from '../../hooks/useRecordingTimer';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CallToolsProps {
  onScreenShare: () => void;
  onSendToAppClick: () => void;
  leave: () => void;
  consumerName: string;
  audioTracks: ITrack[];
}

function CallTools({
  onScreenShare,
  onSendToAppClick,
  leave,
  consumerName,
  audioTracks,
}: CallToolsProps): ReactElement {
  const { guid } = useParams();
  const [appInForeground, setAppInForeground] = useState(true);
  const { sendMessage, sendPayload } = useSignalR();
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [canvasSize, setCanvasSize] = useState<Size>({
    width: 640,
    height: 480,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const videoSize = useRef<Size>({
    width: 640,
    height: 480,
  });

  const {
    appType,
    recordState,
    attendanceId,
    screenShareState,
    openingMeasureApp,
    setEndTime,
    setRecordState,
    setContinueRecordingInMesaureApp,
    continueRecordingInMeasureApp,
    setStartTime,
    startTime,
  } = useBoundStore(
    useShallow((state) => ({
      appType: state.appType,
      recordState: state.recordState,
      attendanceId: state.attendanceId,
      screenShareState: state.screenShareState,
      openingMeasureApp: state.openingMeasureApp,
      setEndTime: state.setEndTime,
      setRecordState: state.setRecordState,
      setContinueRecordingInMesaureApp: state.setContinueRecordingInMesaureApp,
      continueRecordingInMeasureApp: state.continueRecordingInMeasureApp,
      setStartTime: state.setStartTime,
      startTime: state.startTime,
    })),
  );

  useRecordingTimer(!!recordState, startTime, 9, () => {
    if (isAgent) {
      toast(
        <div className='flex flex-row gap-2'>
          <FontAwesomeIcon icon={faTriangleExclamation} size='lg' color='#E65B59' />
          One minute of recording remaining
        </div>,
        {
          className: 'bg-yellow-100',
        },
      );
    }
  });

  useRecordingTimer(!!recordState, startTime, 10, () => {
    if (isAgent) {
      setEndTime(new Date().toISOString());
      setRecordState(false);
      onStopRecording();
      toast('Recording completed.\nPlease start a new recording to continue', {
        icon: '🚫',
      });
    }
  });

  const streamMixer = useRef<StreamMixer>(null);

  const blobOptions: BlobPropertyBag = useMemo(() => {
    return { type: 'video/webm' };
  }, []);
  const mediaRecorderOptions: MediaRecorderOptions = useMemo(() => {
    return { mimeType: 'video/webm' };
  }, []);

  const autoDownload = false;

  const isAgent = appType === AppType.agent;

  const mediaRecorder = useRef<MediaRecorder>(null);
  const recordedBlobs = useRef<Blob[]>([]);

  const firstRender = useRef<boolean>(true);

  useCallMessages({ guid: guid as string, leave, setAppInForeground });

  useEffect(() => {
    getFFmpeg();
    return () => {
      if (recordState) {
        setEndTime(new Date().toISOString());
        onStopRecording();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordState]);

  useEffect(() => {
    if (openingMeasureApp && recordState) {
      setContinueRecordingInMesaureApp(true);
      const time = new Date().toISOString();
      setEndTime(time);
      setRecordState(false);
    } else if (!openingMeasureApp && continueRecordingInMeasureApp) {
      // Need timeout to allow the element time to render with new video dimensions
      setTimeout(() => {
        const time = new Date().toISOString();
        setStartTime(time);
        setRecordState(true);
        setContinueRecordingInMesaureApp(false);
      }, 1500);
    }
  }, [openingMeasureApp, recordState]);

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      // Handle resizing, keeping the video's ratio and
      // containing the canvas in its parent container
      const rect = containerRef.current.getBoundingClientRect();
      const ratio = videoSize.current.width / videoSize.current.height;

      // Fit to width
      let w = rect.width;
      let h = w / ratio;

      // Hack to fix canvas size on first render
      if (firstRender.current) {
        firstRender.current = false;
        setTimeout(() => {
          handleResize();
        }, 1000);
      }

      // Otherwise, fit to height
      if (h > rect.height) {
        h = rect.height;
        w = h * ratio;
      }

      if (canvasSize.width !== w || canvasSize.height !== h) {
        setCanvasSize({
          width: w,
          height: h,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasSize]);

  const onRecorded = useCallback(async () => {
    const blob = new Blob(recordedBlobs.current, blobOptions);

    const ffmpeg = await getFFmpeg();
    const file = await fetchFile(URL.createObjectURL(blob));
    await ffmpeg.writeFile('input.webm', file);
    await ffmpeg.exec(['-i', 'input.webm', '-c', 'copy', 'output.mp4']);
    const data = await ffmpeg.readFile('output.mp4');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newBlob = new Blob([data.buffer], { type: 'video/mp4' });

    await uploadVideo(URL.createObjectURL(newBlob), attendanceId, sendMessage);
  }, [attendanceId, autoDownload, blobOptions, isAgent]);

  const handleDataAvailable = (event: BlobEvent) => {
    if (event.data && event.data.size > 0 && recordedBlobs.current) {
      recordedBlobs.current.push(event.data);
    }
  };

  const getAgoraStream = useCallback(() => {
    // Capture Agora video stream
    const agoraVideoElement = document.querySelector(
      'video.agora_video_player',
    ) as HTMLVideoElement;

    if (agoraVideoElement) {
      const agoraStream = agoraVideoElement.srcObject;

      if (agoraStream) {
        const videoTrack = getVideoTrack(agoraStream as MediaStream);

        if (videoTrack) {
          const settings = videoTrack.getSettings();

          if (
            (settings &&
              settings.width &&
              settings.height &&
              videoSize.current.width !== settings.width) ||
            videoSize.current.height !== settings.height
          ) {
            // Store the current video resolution
            if (settings.width && settings.height) {
              videoSize.current = {
                width: settings.width as number,
                height: settings.height as number,
              };
            }
          }
        }

        return agoraStream;
      }
    }

    return null;
  }, []);

  const onStartRecording = useCallback(async () => {
    if (appType === AppType.consumer) return;

    const agoraStream = getAgoraStream();

    if (agoraStream) {
      streamMixer.current = new StreamMixer();

      // get all audio streams from agora

      // turn them into media streams?????

      // set frame buffer callbacks for all audio streams to get a mediaStream?

      // here is init
      streamMixer.current.init(videoSize.current, [agoraStream], audioTracks);

      streamMixer.current.startMixing();

      // Record the final (mixed) stream
      const s = streamMixer.current.getMixedStream();
      setStream(s);

      recordedBlobs.current = [];

      try {
        mediaRecorder.current = new MediaRecorder(s, mediaRecorderOptions);
      } catch (e) {
        Sentry.captureException(e);
      }

      if (mediaRecorder.current) {
        mediaRecorder.current.onstop = async () => {
          // go through audio streams and remove all the set audio buffer callbacks
          if (mediaRecorder.current) await onRecorded();
        };
        mediaRecorder.current.ondataavailable = handleDataAvailable;
        mediaRecorder.current.start();

        // Notify the consumer we are recording
        sendPayload(ChannelMessage.isRecording, new Date().toISOString());
      }
    }
  }, [appType, getAgoraStream, mediaRecorderOptions, sendPayload, onRecorded, audioTracks]);

  const onStopRecording = useCallback(async () => {
    if (streamMixer.current) {
      streamMixer.current.stopMixing();
      streamMixer.current.dispose();
      streamMixer.current = null;
    }
    if (mediaRecorder.current) mediaRecorder.current.stop();

    // Notify the consumer we stopped recording
    sendMessage(ChannelMessage.isNotRecording);
  }, [sendMessage]);

  useEffect(() => {
    if (recordState && !streamMixer.current) {
      onStartRecording();
      setStartTime(new Date().toISOString());
    }
    if (!recordState && streamMixer.current) {
      onStopRecording();
    }

    getAgoraStream();
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [
    blobOptions,
    mediaRecorderOptions,
    recordState,
    canvasSize,
    stream,
    getAgoraStream,
    handleResize,
    onStartRecording,
    onStopRecording,
    setStartTime,
  ]);

  return (
    <>
      <div ref={containerRef} className='call-tools'>
        {recordState ? (
          <div className={`stream-recorder ${isAgent ? 'mr-[70px]' : ''}`}>
            <div className='status'>
              <span>Recording</span>
              {isAgent ? (
                <span className='recording-dot'>
                  <p className='timer'>
                    <Timer />
                  </p>
                </span>
              ) : (
                <span className='bg-gradient-to-r from-error-600 to-error-700 border-2 border-error-600 h-[25px] w-[25px] rounded-full block ml-2' />
              )}
            </div>
          </div>
        ) : null}
      </div>
      {isAgent && (
        <CallOverlayActions
          onSendToAppClick={onSendToAppClick}
          onScreenShare={onScreenShare}
          sharingEnabled={screenShareState}
        />
      )}

      {screenShareState && <ScreenShareScreen />}
      {!appInForeground && <BackgroundScreen name={consumerName} />}
    </>
  );
}

export default CallTools;
