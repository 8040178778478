const SendToAppIcon = () => {
  return (
    <svg
      className='fill-white'
      height={'30px'}
      width={'30px'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.85001 2C6.33122 2 5.10001 3.23122 5.10001 4.75V19.25C5.10001 20.7688 6.33123 22 7.85001 22H16.15C17.6688 22 18.9 20.7688 18.9 19.25V4.75C18.9 3.23122 17.6688 2 16.15 2H7.85001ZM6.60001 4.75C6.60001 4.05964 7.15965 3.5 7.85001 3.5H8.25V4.45C8.25 5.30604 8.94396 6 9.8 6H14.2C15.056 6 15.75 5.30604 15.75 4.45V3.5H16.15C16.8404 3.5 17.4 4.05964 17.4 4.75V19.25C17.4 19.9404 16.8404 20.5 16.15 20.5H7.85001C7.15965 20.5 6.60001 19.9404 6.60001 19.25V4.75ZM9.75 3.5H14.25V4.45C14.25 4.47761 14.2276 4.5 14.2 4.5H9.8C9.77239 4.5 9.75 4.47761 9.75 4.45V3.5Z'
      />
      <path d='M9 18.75C9 18.3358 9.33579 18 9.75 18H14.25C14.6642 18 15 18.3358 15 18.75C15 19.1642 14.6642 19.5 14.25 19.5H9.75C9.33579 19.5 9 19.1642 9 18.75Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.77 14.005C4.06881 14.2918 4.0785 14.7666 3.79165 15.0654L3.09374 15.7924C2.80689 16.0912 2.33211 16.1009 2.0333 15.8141C1.73449 15.5272 1.7248 15.0524 2.01165 14.7536L2.70956 14.0266C2.99642 13.7278 3.47119 13.7181 3.77 14.005Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 12C0.5 11.5858 0.835786 11.25 1.25 11.25H2.23783C2.65204 11.25 2.98783 11.5858 2.98783 12C2.98783 12.4142 2.65204 12.75 2.23783 12.75H1.25C0.835786 12.75 0.5 12.4142 0.5 12Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.0333 8.18596C2.33211 7.89911 2.80689 7.9088 3.09374 8.20761L3.79165 8.93461C4.0785 9.23342 4.06881 9.7082 3.77 9.99505C3.47119 10.2819 2.99642 10.2722 2.70956 9.9734L2.01165 9.2464C1.7248 8.94759 1.73449 8.47282 2.0333 8.18596Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2306 14.028C19.9318 14.3148 19.9221 14.7896 20.209 15.0884L20.9069 15.8154C21.1937 16.1142 21.6685 16.1239 21.9673 15.837C22.2661 15.5502 22.2758 15.0754 21.989 14.7766L21.291 14.0496C21.0042 13.7508 20.5294 13.7411 20.2306 14.028Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.5006 12.023C23.5006 11.6088 23.1648 11.273 22.7506 11.273H21.7628C21.3486 11.273 21.0128 11.6088 21.0128 12.023C21.0128 12.4372 21.3486 12.773 21.7628 12.773H22.7506C23.1648 12.773 23.5006 12.4372 23.5006 12.023Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.9673 8.20896C21.6685 7.9221 21.1937 7.9318 20.9069 8.23061L20.209 8.95761C19.9221 9.25642 19.9318 9.73119 20.2306 10.018C20.5294 10.3049 21.0042 10.2952 21.291 9.9964L21.989 9.2694C22.2758 8.97059 22.2661 8.49581 21.9673 8.20896Z'
      />
    </svg>
  );
};

export default SendToAppIcon;
