import { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.png';
import logoPrimary from '../../assets/images/logo_primary.png';

interface ImageDimensions {
  width: number;
  height: number;
}

export enum LogoVariant {
  White = 'white',
  Primary = 'primary',
}
interface Props {
  variant?: LogoVariant;
}

function Logo({ variant }: Props) {
  const [imageDimensions, setImageDimensions] = useState<ImageDimensions | null>(null);

  useEffect(() => {
    const image = new Image();
    image.src = logo;

    image.onload = () => {
      setImageDimensions({
        height: image.height,
        width: image.width,
      });
    };
  }, []);

  if (imageDimensions) {
    if (imageDimensions.width > imageDimensions.height) {
      return (
        <img src={variant === LogoVariant.Primary ? logoPrimary : logo} alt='logo' width={250} />
      );
    } else {
      return (
        <img src={variant === LogoVariant.Primary ? logoPrimary : logo} alt='logo' width={50} />
      );
    }
  } else {
    return null;
  }
}

export default Logo;
