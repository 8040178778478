import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './error.scss';
import Logo from '../logo/Logo';

const Error = () => {
  return (
    <div className='error-view full-view fixzy-background'>
      <div className='logo-container'>
        <Logo />
      </div>
      <div className='bottom-section'>
        <FontAwesomeIcon icon={faTriangleExclamation} size='xl' />
        <h1 className='mb-4'>401: Page does not exist</h1>
        <p className='mb-8'>Please log in to the Portal to start a new call.</p>
        <button
          className='contained full-width-300'
          onClick={() => {
            location.href = 'https://portal.app.fixzy.ai';
          }}
        >
          Go To Portal
        </button>
      </div>
    </div>
  );
};

export default Error;
