import { ITrack } from 'agora-rtc-react';
import { CanvasImage, Size } from './drawing';

export type RecordingLayers = {
  pen: HTMLCanvasElement | null;
  pointer: CanvasImage | null;
  paused: CanvasImage | null;
};

export default class StreamMixer {
  streams: MediaProvider[] | null = null;
  audioTracks: ITrack[] | null = null;
  gainNode: GainNode | null = null;
  audioDestination: MediaStreamAudioDestinationNode | null = null;
  loadedStreamCount = 0;
  frameInterval = 25;
  drawingInterval = 10;
  outputSize: Size = {
    width: 640,
    height: 480,
  };
  canvas: HTMLCanvasElement | null = null;
  canvasContext: CanvasRenderingContext2D | null = null;
  videos: HTMLVideoElement[] = [];
  audioContext: AudioContext | null = null;
  audioSources: MediaStreamAudioSourceNode[] = [];
  timeoutRef: NodeJS.Timeout | null = null;

  showPreview = false;

  getOutputSize() {
    return this.outputSize;
  }

  init(outputSize: Size, streams: MediaProvider[], audioTracks: ITrack[] = []) {
    this.outputSize = outputSize;
    this.streams = [...streams];

    //this is only ever the agents audio
    this.audioTracks = [...audioTracks];

    this.videos = [];

    this.streams.forEach((s) => {
      const track = getVideoTrack(s as MediaStream);

      if (!track) throw 'Unexpected non-video track';

      // Generate in memory a video from the provided stream
      this.videos.push(createVideo(s));
    });

    const canvas = document.getElementById('outputCanvas');

    // Create output (off screen) canvas if not existing
    if (!canvas) {
      let style =
        'position: absolute; pointer-events: none; width: ' +
        this.outputSize.width +
        'px; height: ' +
        this.outputSize.height +
        'px;';

      if (!this.showPreview)
        style +=
          'opacity:0; z-index:-1; top: -1000000; left:-10000000; margin-top:-10000000; margin-left:-10000000;';
      else style += 'scale: 0.5;';

      this.canvas = document.createElement('canvas');
      this.canvas.id = 'outputCanvas';
      this.canvas.setAttribute('style', style);

      this.canvas.width = this.outputSize.width;
      this.canvas.height = this.outputSize.height;

      document.body.appendChild(this.canvas);
    } else {
      this.canvas = canvas as HTMLCanvasElement;
    }
  }

  // It accepts the streams and generate a canvas mixing them all
  startMixing() {
    if (!this.canvas) throw 'Canvas not initialized';

    this.canvasContext = this.canvas.getContext('2d');
    this.drawVideosToCanvas();
  }

  isMixing() {
    return this.timeoutRef !== null;
  }

  private drawVideosToCanvas() {
    this.videos.forEach((stream) => this.drawImage(stream));

    // Here the canvasContext could also be used to draw on the Canvas

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }

    this.timeoutRef = setTimeout(this.drawVideosToCanvas.bind(this), this.drawingInterval);
  }

  private drawImage(video: CanvasImageSource) {
    if (this.canvasContext) {
      // bottom layer is the video
      this.canvasContext.drawImage(video, 0, 0, this.outputSize.width, this.outputSize.height);
    }
  }

  getMixedStream() {
    // Combine audio and video stream

    const audioStream = this.getMixedAudioStream();
    const videoStream = this.getMixedVideoStream();

    if (audioStream !== null) {
      audioStream.getTracks().forEach((audioTrack) => {
        videoStream.addTrack(audioTrack);
      });
    }

    return videoStream;
  }

  getCanvas(): HTMLCanvasElement | null {
    return this.canvas;
  }

  private getMixedVideoStream(): MediaStream {
    if (!this.canvas) throw 'Output canvas not initialized';

    return this.canvas.captureStream(this.frameInterval);
  }

  private getMixedAudioStream(): MediaStream | null {
    if (!this.audioContext) this.audioContext = new AudioContext();

    this.audioSources = [];
    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);
    this.gainNode.gain.value = 0; // don't hear self

    if (this.audioTracks)
      this.audioTracks.forEach((track) => {
        const audioStream = new MediaStream();
        audioStream.addTrack(track.getMediaStreamTrack());
        const audioSource = (this.audioContext as AudioContext).createMediaStreamSource(
          audioStream,
        );
        audioSource.connect(this.gainNode as AudioNode);
        this.audioSources.push(audioSource);
      });

    if (!this.audioSources.length) return null;

    this.audioDestination = this.audioContext.createMediaStreamDestination();

    this.audioSources.forEach((source) => {
      source.connect(this.audioDestination as AudioNode);
    });

    return this.audioDestination.stream;
  }

  stopMixing() {
    // Stop to draw the mixed streams canvas
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
  }

  dispose() {
    const canvas = document.getElementById('outputCanvas');

    if (canvas) canvas.remove();

    this.videos = [];

    this.canvas = null;

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
  }
}

function createVideo(stream: MediaProvider) {
  const video = document.createElement('video');
  video.srcObject = stream;
  video.muted = true;
  video.play();
  return video;
}

export function getVideoTrack(stream: MediaStream) {
  const tracks = stream.getTracks().filter((t) => t.kind === 'video');
  if (tracks.length === 0) return null;
  return tracks[0];
}

export async function getCameraStream() {
  return navigator.mediaDevices.getUserMedia({ video: true, audio: true });
}

export async function getDevices() {
  return navigator.mediaDevices.enumerateDevices();
}
