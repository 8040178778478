const EndCallIcon = () => {
  return (
    <svg
      width={'35px'}
      height={'35px'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40.561 40.561'
    >
      <path
        id='Path_456'
        data-name='Path 456'
        d='M12.044,16.755c2.789,2.766,6.05,5.375,7.469,3.947,2.031-2.044,3.309-3.795,7.382-.634s.687,5.636-1.28,7.617c-2.27,2.285-10.2.725-17.731-6.741S-1.267,5.564,1,3.277C2.972,1.3,5.421-2.1,8.615,1.943s1.453,5.335-.578,7.379C6.62,10.75,9.255,13.988,12.044,16.755Z'
        transform='translate(40.635 20.222) rotate(135)'
        fill='#fff'
      />
    </svg>
  );
};

export default EndCallIcon;
