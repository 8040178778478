const ScreenShareIcon = ({ enabled }: { enabled: boolean }) => {
  return (
    <svg
      className={`${enabled ? 'fill-secondary-700' : 'fill-white'}`}
      height={'30px'}
      width={'30px'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.75 2C3.23122 2 2 3.23122 2 4.75V14.75C2 16.2688 3.23122 17.5 4.75 17.5H8.50003L7.65814 20.0257C7.33438 20.997 8.05733 22 9.08117 22H14.9189C15.9427 22 16.6657 20.997 16.3419 20.0257L15.5 17.5H19.25C20.7688 17.5 22 16.2688 22 14.75V4.75C22 3.23122 20.7688 2 19.25 2H4.75ZM13.9189 17.5H10.0812L9.08117 20.5H14.9189L13.9189 17.5ZM13.9189 16H19.25C19.9404 16 20.5 15.4404 20.5 14.75V4.75C20.5 4.05964 19.9404 3.5 19.25 3.5H4.75C4.05964 3.5 3.5 4.05964 3.5 4.75V14.75C3.5 15.4404 4.05964 16 4.75 16H10.0812H13.9189Z'
      />
    </svg>
  );
};

export default ScreenShareIcon;
