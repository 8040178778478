export enum AppType {
  agent = 'agent',
  consumer = 'consumer',
}

export enum ChannelMessage {
  isScreenShare = 'isScreenShare',
  isNotScreenShare = 'isNotScreenShare',
  openApp = 'openApp',
  isRecording = 'isRecording',
  isNotRecording = 'isNotRecording',
  callHasEnded = 'callHasEnded',
  appBackgrounded = 'appBackgrounded',
  appForegrounded = 'appForegrounded',
  appJoined = 'appJoined',
  appEndedCall = 'appEndedCall',
  isStreamNotSent = 'isStreamNotSent',
  isStreamSent = 'isStreamSent',
  openingMeasureApp = 'openingMeasureApp',
  isUploading = 'isUploading',
  isNotUploading = 'isNotUploading',
}

export enum JoinState {
  inactive = 0,
  joining = 1,
  joined = 2,
}

export enum ProxyModes {
  none = 0,
  forceUdp = 3,
  forceTcp = 5,
}
