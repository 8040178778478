import { useBoundStore } from '@fixzy/agent-app/src/store';
import { uploadBase64Photo } from '../../api/helpers';

import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSignalR } from '../../hooks';
import CallActionButton from './callActionButton';
import toast from 'react-hot-toast';
import SendToAppIcon from '../icons/SendToAppIcon';
import ScreenShareIcon from '../icons/ScreenShareIcon';

export interface CallOverlayActionsProps {
  video?: Element | null | undefined;
  onScreenShare: () => void;
  onSendToAppClick: () => void;
  sharingEnabled: boolean;
}

export const CallOverlayActions = ({
  video,
  onScreenShare,
  onSendToAppClick,
  sharingEnabled,
}: CallOverlayActionsProps) => {
  const { sendMessage } = useSignalR();
  const attendanceId = useBoundStore((state) => state.attendanceId);

  const takeScreenShot = (): string | null => {
    const videoElement = (
      video ? video : document.querySelector('video.agora_video_player')
    ) as HTMLVideoElement;

    if (videoElement) {
      const w = videoElement.videoWidth;
      const h = videoElement.videoHeight;

      const canvas = document.createElement('canvas');

      canvas.width = w;
      canvas.height = h;

      const canvasContext = canvas.getContext('2d');

      if (canvasContext) {
        canvasContext.drawImage(videoElement, 0, 0, w, h);
      }

      return canvas.toDataURL('image/png');
    }

    return null;
  };

  const onVideoImageCapture = async () => {
    const screenshot = takeScreenShot();

    if (screenshot) {
      toast.success('Snapshot captured');
      await uploadBase64Photo(screenshot, attendanceId, sendMessage);
    }
  };

  return (
    <div className='absolute top-4 right-4 bottom-4 flex flex-col gap-4 justify-center'>
      <CallActionButton
        onClick={onVideoImageCapture}
        tooltipText='Take photo'
        icon={<FontAwesomeIcon icon={faCamera} size='xl' className={'icon'} />}
        trackingLabel='Take photo'
      />
      <CallActionButton
        onClick={onSendToAppClick}
        icon={<SendToAppIcon />}
        tooltipText='Open measure app'
        trackingLabel='Open measure app'
      />
      <CallActionButton
        onClick={onScreenShare}
        active={sharingEnabled}
        icon={<ScreenShareIcon enabled={sharingEnabled} />}
        tooltipText='Screen share'
        trackingLabel='Screen share'
      />
    </div>
  );
};
