import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';

export interface Note {
  attendanceId: string;
  description: string;
  timeStamp: string;
}

export interface NoteInputParams {
  attendanceId: string;
  addNote: (note: Note) => Promise<void>;
}

type DebouncedFunction<T extends (...args: any[]) => void> = (...args: Parameters<T>) => void;

function debounce<T extends (...args: any[]) => void>(func: T, wait: number): DebouncedFunction<T> {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return function (...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export const NoteInput = ({ addNote, attendanceId }: NoteInputParams) => {
  const emptyNote = useMemo(() => ({ attendanceId, description: '' }), [attendanceId]);
  const [note, setNote] = useState(emptyNote);
  const [isUploading, setIsUploading] = useState(false);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSaveClick();
      e.preventDefault();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote({ ...note, description: e.target.value });
  };

  const onSaveClick = useMemo(
    () =>
      debounce(async () => {
        if (note && note.description) {
          setIsUploading(true);
          await addNote({ ...note, timeStamp: dayjs(new Date()).format() });
          setNote(emptyNote);
          setIsUploading(false);
        }
      }, 300),
    [addNote, emptyNote, note],
  );

  return (
    <div className='flex items-center rounded-xl bg-white pr-4 mb-4'>
      <textarea
        className='flex-1 p-4 mr-4 h-[75px] resize-none rounded-xl'
        id='note-input'
        draggable={false}
        placeholder='Type new note here...'
        value={note.description}
        onKeyDown={(e) => onKeyDown(e)}
        onChange={(e) => onChange(e)}
      />
      <button
        className='h-[50px] px-8 bg-secondary-700 rounded-xl text-white cursor-pointer font-bold hover:bg-secondary-500 disabled:bg-secondary-300 disabled:cursor-not-allowed'
        id='save-note'
        disabled={!note.description || isUploading}
        onClick={onSaveClick}
        onKeyDown={onSaveClick}
      >
        Save
      </button>
    </div>
  );
};
