import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from '@fixzy/agent-app/src/store';
import { AppType } from 'fixzy-dialler-common/enums';

import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../callNavBar/callNavBar.scss';
import { useKeybind } from '../../hooks';
import Logo from '../logo/Logo';
import EndCallIcon from '../icons/EndCallIcon';
import CameraOffIcon from '../icons/CameraOffIcon';

export const NAV_BAR_HEIGHT = 85;

interface NavBarProps {
  toggleMute: () => void;
  muted: boolean;
  setEndCall: React.Dispatch<boolean>;
}

export const CallNavBar = ({ toggleMute, muted, setEndCall }: NavBarProps) => {
  const [showUnableToEndCall, setShowUnableToEndCall] = useState(false);

  const { appType, recordState, setRecordState, setStartTime, setEndTime, uploadState } =
    useBoundStore(
      useShallow((state) => ({
        appType: state.appType,
        recordState: state.recordState,
        setRecordState: state.setRecordState,
        setStartTime: state.setStartTime,
        setEndTime: state.setEndTime,
        uploadState: state.uploadState,
      })),
    );

  useEffect(() => {
    if ((!recordState || !uploadState) && showUnableToEndCall) {
      setShowUnableToEndCall(false);
    }
  }, [recordState, uploadState, showUnableToEndCall]);

  useKeybind((event) => {
    if (event.key === 'm' && document.activeElement?.id !== 'note-input') {
      toggleMute();
    }
  });

  const onEndCall = () => {
    if (recordState || uploadState) {
      setShowUnableToEndCall(true);
    } else {
      setEndCall(true);
    }
  };

  const renderEndCallButton = () => (
    <div className='relative flex mt-[-40px] bg-primary-700 rounded-full justify-center items-center w-[80px] h-[80px]'>
      <button
        className={`end-call ${recordState || uploadState ? 'disabled' : ''}`}
        tabIndex={0}
        onClick={onEndCall}
      >
        <EndCallIcon />
      </button>
      {showUnableToEndCall && (
        <div className='unable-to-end-call'>
          You can not end the call whilst {uploadState ? 'uploading' : 'recording'}.
        </div>
      )}
    </div>
  );

  const renderMuteButton = () => (
    <div className='flex flex-1 justify-center'>
      <button
        className='relative flex flex-col justify-center items-center stroke-white text-white'
        onClick={() => toggleMute()}
      >
        <FontAwesomeIcon icon={faMicrophone} size='xl' />
        {muted ? (
          <div className='absolute top-4 left-2 bg-error-600 h-[4px] w-[40px] transform rotate-45 ' />
        ) : null}
        <span>{muted ? 'Unmute' : 'Mute'}</span>
      </button>
    </div>
  );

  const renderAgentButtons = () => (
    <>
      <div className='flex flex-1 justify-center'>
        <button
          className='relative flex flex-col items-center stroke-white text-white'
          onClick={() => {
            const time = new Date().toISOString();
            recordState ? setEndTime(time) : setStartTime(time);
            setRecordState(!recordState);
          }}
        >
          {recordState ? (
            <div className='h-[35px] w-[35px] bg-error-600 rounded-full flex justify-center items-center'>
              <div className='h-[15px] w-[15px] bg-white rounded-sm' />
            </div>
          ) : (
            <CameraOffIcon />
          )}
          <span>{recordState ? 'Stop' : 'Record'}</span>
        </button>
      </div>
      {renderEndCallButton()}
      {renderMuteButton()}
    </>
  );

  const renderConsumerButtons = () => {
    return (
      <>
        <div className='flex flex-1 justify-start'>
          <div className='w-3/4'>
            <Logo />
          </div>
        </div>
        {renderEndCallButton()}
        {renderMuteButton()}
      </>
    );
  };

  return (
    <>
      <div className={`flex bg-primary-700 h-[85px] items-center`}>
        <div className='flex flex-1 items-center px-2'>
          {appType === AppType.consumer ? renderConsumerButtons() : renderAgentButtons()}
        </div>
      </div>
    </>
  );
};
