import { useMemo } from 'react';
import { Note, NoteInput } from './noteInput';
import dayjs from 'dayjs';

import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface NoteDisplayProps {
  notes: Note[];
  attendanceId: string;
  addNote: (note: Note) => Promise<void>;
}

export const NotesGallery = ({ notes, attendanceId, addNote }: NoteDisplayProps) => {
  const orderedNotes = useMemo(() => {
    return notes.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());
  }, [notes]);

  return (
    <div className='flex flex-col h-full py-4'>
      <div className='px-4'>
        <div className='flex items-center mb-2'>
          <h3 className='text-gray-800 mb-1'>Notes</h3>
          {orderedNotes.length > 0 && (
            <p className='bg-secondary-100 text-sm text-secondary-700 rounded-full text-lg ml-2 px-2 py-1'>
              {orderedNotes.length} {orderedNotes.length === 1 ? 'item' : 'items'}
            </p>
          )}
        </div>
        <NoteInput attendanceId={attendanceId} addNote={addNote} />
      </div>
      {orderedNotes.length === 0 ? (
        <div className='flex-1 flex-col flex justify-center items-center bg-white rounded-xl mx-4'>
          <div className='h-[50px] w-[50px] rounded-full bg-secondary-100 flex justify-center items-center'>
            <FontAwesomeIcon icon={faNoteSticky} className='text-secondary-700' size='lg' />
          </div>
          <h4 className='text-gray-800'>No Notes</h4>
          <p>Press &quot;Save&quot; to add a note</p>
        </div>
      ) : (
        <div className='flex-1 overflow-y-auto'>
          <div className='px-4'>
            {orderedNotes.map((n, index) => (
              <div className='flex items-start bg-white p-4 mb-4 rounded-xl' key={`note_${index}`}>
                <FontAwesomeIcon
                  icon={faNoteSticky}
                  className='text-secondary-700 mr-4'
                  size='lg'
                />
                <div data-clarity-mask='true'>
                  <p className='text-gray-800 text-xs'>
                    {dayjs(n.timeStamp).format('DD/MM/YY HH:mm')}
                  </p>
                  <p className='text-gray-800 text-md'>{n.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
