import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './openAppButton.scss';
import { useSignalR } from '../../hooks';
import { ChannelMessage } from '../../../enums';
import config from '../../config';

export interface OpenAppButtonProps {
  attendanceId: string;
  onClickClose: () => void;
}

export const OpenAppButton = ({ attendanceId, onClickClose }: OpenAppButtonProps) => {
  const { sendMessage } = useSignalR();

  const onOpenClick = () => {
    // send signal r
    sendMessage(ChannelMessage.openingMeasureApp);

    const url = config.DYNAMIC_APP_URL.replace('{SESSION_ID}', attendanceId);
    window.open(url, '_blank');
  };

  return (
    <div className='open-app-button-container'>
      <button className='close-button contained' onClick={onClickClose}>
        <FontAwesomeIcon icon={faX} width={16} height={16} />
      </button>
      <button className='contained open-app-button' onClick={onOpenClick}>
        Open App
      </button>
    </div>
  );
};
