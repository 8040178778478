import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// styles
import 'fixzy-dialler-common/src/styles/ui.scss';

import './i18n';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
