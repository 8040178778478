import { faChevronRight, faChevronLeft, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useBoundStore } from '@fixzy/agent-app/src/store';

interface ImagePreviewProps {
  previewIndex: number;
  setPreviewIndex: (val: number | null) => void;
}

export const ImagePreview = ({ previewIndex, setPreviewIndex }: ImagePreviewProps) => {
  const takenImages = useBoundStore((state) => state.takenImages);
  const image = takenImages[previewIndex];

  return (
    <div className='flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 bg-primary-700/70 gap-2'>
      {previewIndex > 0 ? (
        <button
          onClick={() => setPreviewIndex(previewIndex - 1)}
          className='h-[70px] w-[70px] rounded-full flex justify-center items-center bg-secondary-700 hover:cursor-pointer hover:bg-secondary-500'
        >
          <FontAwesomeIcon icon={faChevronLeft} className='text-white' size='2xl' />
        </button>
      ) : (
        <div className='w-[70px]' />
      )}
      <div className='h-[500px] flex justify-center items-center'>
        {image.uploading ? (
          <p>Uploading...</p>
        ) : (
          <img src={image.src} alt='#' className='img-preview' />
        )}
      </div>
      {previewIndex < takenImages.length - 1 ? (
        <button
          onClick={() => setPreviewIndex(previewIndex + 1)}
          className='h-[70px] w-[70px] rounded-full flex justify-center items-center bg-secondary-700 hover:cursor-pointer hover:bg-secondary-500'
        >
          <FontAwesomeIcon icon={faChevronRight} className='text-white' size='2xl' />
        </button>
      ) : (
        <div className='w-[70px]' />
      )}
      <button
        onClick={() => setPreviewIndex(null)}
        className='absolute top-4 right-4 h-[70px] w-[70px] rounded-full flex justify-center items-center bg-white hover:cursor-pointer hover:bg-secondary-100'
      >
        <FontAwesomeIcon icon={faX} className='text-secondary-700' size='2xl' />
      </button>
    </div>
  );
};
