import '../loadingScreen/loadingScreen.scss';
import { useBoundStore } from '@fixzy/agent-app/src/store';
import Logo from '../logo/Logo';
import EndCallIcon from '../icons/EndCallIcon';

interface LoadingScreenProps {
  name: string;
  onLeave: () => void;
}

export const LoadingScreen = ({ name, onLeave }: LoadingScreenProps) => {
  const callHasEnded = useBoundStore((state) => state.setEndCallState);

  const leaveCall = () => {
    onLeave();
    callHasEnded(true);
  };

  return (
    <div className='page-container'>
      <div className='caller-container'>
        <Logo />
        <h1 className='text-white'>{name}</h1>
        <p className='text-white'>Call connecting...</p>
      </div>
      <button className='end-call' onClick={leaveCall}>
        <EndCallIcon />
      </button>
    </div>
  );
};
